import { Action, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../reducers";
import BackendServices from "../../../components/Api/BackendService";
import TableInputFilter from "../../../Models/common/TableInputFilter";
export const ACTION_GET_TABLE_FILTER_INPUTS =
  "ACTION_GET_TABLE_FILTER_INPUTS";

  export const ACTION_CLEAR_TABLE_FILTER_INPUTS =
  "ACTION_CLEAR_TABLE_FILTER_INPUTS";
  
  export const ACTION_RESET_TABLE_FILTER_SEARCHTERM =
  "ACTION_RESET_TABLE_FILTER_SEARCHTERM";


  
  interface SetTableInputs{
    type:typeof ACTION_GET_TABLE_FILTER_INPUTS;
    payload:TableInputFilter
  }

  interface ClearTableInputs{
    type:typeof ACTION_CLEAR_TABLE_FILTER_INPUTS;
  }
  interface ResetSearchTerm{
    type:typeof ACTION_RESET_TABLE_FILTER_SEARCHTERM;
    payload:string
  }

  export type ActionType= SetTableInputs|ClearTableInputs|ResetSearchTerm;

  export const setTableInputs=(tableInputs:TableInputFilter):SetTableInputs=>(
    {
      type:ACTION_GET_TABLE_FILTER_INPUTS,
      payload:tableInputs
    }
  )
  export const clearTableInputs=():ClearTableInputs=>({
    type:ACTION_CLEAR_TABLE_FILTER_INPUTS
  })
  export const resetSearchTerm=(searchTerm:string):ResetSearchTerm=>({
    type:ACTION_RESET_TABLE_FILTER_SEARCHTERM,
    payload:searchTerm
  })
