/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AxiosResponse } from "axios";
import SearchableDropdown from "../../../components/SearchableDropdown";
import useGetRoles from "./http/useGetRoles";
import useGetSecondaryOrganisations from "./http/useGetSecondaryOrganisations";
import postUser, { PostUserBody } from "./http/postUser";
import {
  MinifiedOrganisation,
  minifiedOrganisationToString,
  MinifiedOrganisationType,
} from "./constants";
import UserDetails from "../models/UserDetails";
import putUser from "./http/putUser";
import BackendServices from "../../../components/Api/BackendService";
import mapPrimaryOrgs from "./util/mapPrimaryOrgs";
import mapMinifiedOrgToOrg from "./util/mapMinifiedOrgToOrg";
import { mapOrgsToMinifiedOrgs } from "./util/mapOrgsToMinifiedOrgs";
import usePrimaryOrganisation from "./http/usePrimaryOrganisation";
import MultipleSelectDropdown from "../../../components/MultipleSelectDropdown";
import DebouncedSearchSelector from "../../../components/DebouncedSearchSelector";
import axiosApi from "../../../components/Api/Axios";
import Option from "../../../Models/forms/Option";
import Snackbar from "../../../components/Snackbar";
import CreatePage from "../../../components/CreatePage";
import { COMPANY_USER_ORIGIN } from "../../CompanyManagement/CompanyNew";
import { RootState } from "../../../store/reducers";
import UserParent from "./util/UserParent";
import { FieldValidator } from "../../../components/FieldValidator";
import CompanySubmissionState from "./constants/CompanySubmissionState";
import { RetryButton, SubmitButton } from "../../../components/Button/button";
import history from "../../../components/Common/History";
import FormGridItem from "../../../components/Common/FormGridItem";
import { CompanyUrls } from "../../CompanyManagement";
import { Button } from "@material-ui/core";
import { current } from "@reduxjs/toolkit";
import { isCompanyUser } from "../../../Authentication/Auth";
import UserRole from "../../../Models/user/UserRole";

interface UserNewProps {
  readonly userId: string | null;
  readonly userData: UserDetails | null;
  readonly isEditMode: boolean | null;
  
}

export interface UserParentIds {
  readonly companyId: string | null;
  readonly siteId: string | null;
}

const UserNew = ({ userId, userData, isEditMode }: UserNewProps) => {
  const [jobTitle, setJobTitle] = useState(userData?.jobTitle ?? "");
  const [email, setEmail] = useState(userData?.email ?? "");
  const [firstName, setFirstName] = useState(userData?.firstName ?? "");
  const [lastName, setLastName] = useState(userData?.lastName ?? "");
  const [phoneNumber, setPhoneNumber] = useState(userData?.phone ?? "");
  const params = new URLSearchParams(window.location.search);
  const isCompanyCreation = params.get("origin") === COMPANY_USER_ORIGIN;
  const companyData = useSelector((state: RootState) => state.company.postBody);
  const fullEditMode = userData?.allowFullEdit;
  const [overrideAllowed, setOverrideAllowed] = useState(false);
  
  
  const parentIds: UserParentIds = {
    companyId: params.get(UserParent.COMPANY),
    siteId: params.get(UserParent.SITE),
  };

  const [checkUser,setCheckUser]=useState(false);
  const {
    setPrimaryOrganisation,
    primaryOrganisation,
    isLoadingParent,
    isPreset,
  } = usePrimaryOrganisation(userData, parentIds, isCompanyCreation);

  const [
    selectedSecondaryOrganisations,
    setSelectedSecondaryOrganisations,
  ] = useState<MinifiedOrganisation[]>(
    userData?.secondaryOrganisations
      ? mapOrgsToMinifiedOrgs(userData?.secondaryOrganisations)
      : []
  );

  const {
    isLoading,
    roles,
    setRole,
    role,
    isPreset: isRolePreset,
  } = useGetRoles(userData, parentIds, isCompanyCreation);

  const { secondaryOrganisations } = useGetSecondaryOrganisations(
    primaryOrganisation
  );
  const [companySubmissionState, setCompanySubmissionState] = useState<
    CompanySubmissionState
  >(CompanySubmissionState.PRE_START);
  const [userError, setUserError] = useState<string | null>(null);
  const [createdCompanyId, setCreatedCompanyId] = useState<null | number>(null);
  const [companyUser,setCompanyuser]=useState([
   
    UserRole.COMPANY_BI_BO_USER,
    UserRole.COMPANY_BI_SC_USER,
    UserRole.COMPANY_BI_S_USER,
    UserRole.COMPANY_USER,
  ])
  const [isCompanyUser,CompanyUser]=useState(Object.values(companyUser).includes(userData?.roleName as UserRole)==true?true:false);
 
  if (isLoading || isLoadingParent) return <div>Loading</div>;
  
  const handleChangeRole = (value: Option | null) => {
   
    const companyUserExistance=Object.values(companyUser).includes(value?.label as UserRole);
    
    if(!companyUserExistance)
    {
      CompanyUser(false);
      setJobTitle("");
     
    }
    if(companyUserExistance)
      {
        CompanyUser(true);
        setJobTitle("");
       
      }
    setRole(value?.label || "");
   
    
    handleChangePrimaryOrganisation(null);
  };
  const handleChangePrimaryOrganisation = (
    value: Option<MinifiedOrganisationType> | null
  ) => {
    if (isPreset) return;
    setPrimaryOrganisation(
      value
        ? {
            externalId:
              typeof value.value === "string"
                ? parseInt(value.value, 10)
                : value.value,
            name: value.label,
            type: value.type || value.type === 0 ? value.type : -1,
          }
        : null
    );
    setSelectedSecondaryOrganisations([]);
  };

  const userCreationFailedPostCompanyCreation = [
    CompanySubmissionState.USER_FAILED_GENERIC,
    CompanySubmissionState.USER_FAILED_DUPLICATE,
  ].includes(companySubmissionState);

  const submitCompany = async (user: PostUserBody): Promise<any> => {
    if (!companyData) return Promise.reject();
    
      try {
        const companyId= await axiosApi
          .post<number>(`${BackendServices.COMPANY_SERVICE.COMPANY}`, companyData)
          .then(({ data }) => {
            setCreatedCompanyId(data);
            return data;
          });
          
        return postUser({
          ...user,
          primaryOrganisation: {
            ...user.primaryOrganisation,
            externalId: companyId,       
          },
        })
          .then(() =>
            history.push(
              createdCompanyId
                ? CompanyUrls.details(createdCompanyId)
                : CompanyUrls.searchTable
            )
          )
          .catch(({ response }) => {
            handleUserPostError(response);
            setCompanySubmissionState(
              response.status === 400
                ? CompanySubmissionState.USER_FAILED_DUPLICATE
                : CompanySubmissionState.USER_FAILED_GENERIC
            );
          });
      } catch (e) {
        return Promise.reject();
      }
    
    
  };

  
  const handleSubmit = async () => {
    if (!primaryOrganisation) return Promise.reject();
    const overrideWarning = false;
      const user: PostUserBody = {
      email,
      firstName,
      lastName,
      phoneNumber,
      role,
      jobTitle,
      primaryOrganisation: mapMinifiedOrgToOrg(primaryOrganisation),
      secondaryOrganisations: selectedSecondaryOrganisations.map((org) =>
        mapMinifiedOrgToOrg(org)
      ),
      overrideWarning,
    };  
            if (isCompanyCreation) {

        const checkUser= await axiosApi.get<boolean>(`${BackendServices.USER_SERVICE.USER}/checkUser?email=${user.email}`).then(({data})=>{setCheckUser(data);return data;});
        if(checkUser) 
        {
          return submitCompany(user);
        }
        else
        {
          setUserError(`User ${user.email} already exists in Directory`);  
          setCompanySubmissionState(
            checkUser === false
              ? CompanySubmissionState.USER_FAILED_DUPLICATE
              : CompanySubmissionState.USER_FAILED_GENERIC
          );
          return Promise.reject();  
        };
             
    }         
    
    if (isEditMode) {
     
      return putUser(user, userId);
    }

    const x = overrideAllowed;
    user.overrideWarning = x;
    return postUser(user)
      .then(() => history.goBack())
      .catch((error) => handleUserPostError(error.response));
  };

  
  
  const loadUserFromB2C = (userEmail: string) => {
    const returnedUser = JSON.parse(userEmail);
    setEmail(returnedUser.Email);
    setJobTitle(returnedUser.jobTitle);
    setFirstName(returnedUser.FirstName);
    setLastName(returnedUser.LastName);
    setPhoneNumber(returnedUser.PhoneNumber);
    setUserError(
      `User ${returnedUser.Email} already exists in LGC systems. Click on “Submit” to assign a role and organisation to give them access to Directory. Greyed out fields are populated with existing user data`
    );
    setOverrideAllowed(true);
    disableField("Email");
    disableField("LastName");
    disableField("FirstName");
    disableField("PhoneNumber");
    disableField("JobTitle");
  };
  const disableField = (fieldName: string) => {
    const nodes = document.getElementsByName(fieldName);
    nodes.forEach((node) => {
      node.setAttribute("disabled", "true");
      node.style.backgroundColor = "#BEBEBE";
    });
  };
  const handleUserPostError = (response: AxiosResponse) => {
    switch (response.status) {
      case 400:
        setUserError(response.data);
        break;
      case 409: // User exists but not in directory
        loadUserFromB2C(response.data);
        break;
      default:
        setUserError("User creation failed - unknown error");
        break;
    }
  };

  const isDisabled = () => {
    let disabled = false;
    if (isEditMode) {
      disabled = !fullEditMode;
    }
    return disabled;
  };

  return (
    <>
      <CreatePage
        title={isEditMode ? "Edit user" : "Add new user"}
        subtitle={`${firstName} ${lastName}`}
        onSubmit={handleSubmit}
        actionButtonArea={
          userCreationFailedPostCompanyCreation ? (
            <FormGridItem>
              <p>
                Error:{" "}
                {companySubmissionState ===
                CompanySubmissionState.USER_FAILED_DUPLICATE
                  ? "User not created and email already exists. Provide alternative email address to proceed"
                  : userError}
              </p>
              <RetryButton onClick={handleSubmit}/>
            </FormGridItem>
          ) : undefined
        }
        fields={[
     
          {
            name: "Email",
            label: "Email",
            value: email,
            setTextValue: setEmail,
            validators: [FieldValidator.EMAIL],
            disabled: !!userData?.email || isDisabled(),
            required: true,
          },
          {
            name: "FirstName",
            label: "First Name",
            value: firstName,
            setTextValue: setFirstName,
            disabled: isDisabled(),
            required: true,
          },
          {
            name: "LastName",
            label: "Last Name",
            value: lastName,
            setTextValue: setLastName,
            disabled: isDisabled(),
            required: true,
          },
             
        ...isCompanyUser? [ {
          name: "JobTitle",
          label: "Job Title",
          value: jobTitle,
          setTextValue: setJobTitle,
          disabled: isDisabled(),
          required: true,
        }]:[],
          {
            name: "PhoneNumber",
            label: "Phone number",
            value: phoneNumber,
            setTextValue: setPhoneNumber,
            disabled: isDisabled(),
            validators: [FieldValidator.PHONE_NUMBER],
          },
          {
            name: "role",
            render: () => (
              <SearchableDropdown
                required
                name="role"
                disabled={isRolePreset}
                Options={roles.map((_role) => ({ label: _role, value: _role }))}
                label="Role "
                value={role ? { value: "", label: role } : null}
                onChange={(_, value) => handleChangeRole(value)}
              />
            ),
          },
          {
            name: "primary",
            render: () =>
              role || isPreset ? (
                <DebouncedSearchSelector<MinifiedOrganisationType>
                  required
                  disabled={isPreset}
                  label="Primary organisation "
                  getOptions={(input) =>
                    axiosApi
                      .get(
                        `${BackendServices.USER_SERVICE.USER}/primaryOrganisations`,
                        { params: { role, query: input } }
                      )
                      .then(({ data }) => mapPrimaryOrgs(data))
                  }
                  value={
                    primaryOrganisation
                      ? {
                          value: primaryOrganisation.externalId,
                          label: primaryOrganisation.name,
                          type: primaryOrganisation.type,
                        }
                      : null
                  }
                  onSelect={(option: Option<MinifiedOrganisationType> | null) =>
                    handleChangePrimaryOrganisation(option)
                  }
                  groupBy={minifiedOrganisationToString}
                />
              ) : null,
          },
          {
            name: "secondary",
            render: () =>
              primaryOrganisation && secondaryOrganisations.length > 0 ? (
                <MultipleSelectDropdown
                  label="Secondary organisations (optional)"
                  options={secondaryOrganisations.map((org) => ({
                    label: org.name,
                    value: org.externalId,
                  }))}
                  value={selectedSecondaryOrganisations.map((org) => ({
                    value: org.externalId,
                    label: org.name,
                  }))}
                  onChange={(values) => {
                    setSelectedSecondaryOrganisations(
                      values
                        .map((val) =>
                          secondaryOrganisations.find(
                            (org) => org.externalId === val.value
                          )
                        )
                        .filter((val) => !!val) as MinifiedOrganisation[]
                    );
                  }}
                />
              ) : null,
          },
        ]}
      />
      <Snackbar
        type="error"
        open={!!userError}
        message={userError ?? ""}
        hideAfter={null}
        close={() => setUserError(null)}
      />
    </>
  );
};

export default UserNew;
