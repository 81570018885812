import SharingType from "../models/SharingType";

export default (type: SharingType): { [key: string]: string } => {
  switch (type) {
    case SharingType.AUDIT:
      return {
        "1": "Certification body",
        "2": "Company",
        //"3": "Site",
      };
    default:
      return {};
  }
};
