import React, {useState } from "react";
import DataTable, { DataTableQueryParams } from "../../../components/DataTable";
import axiosApi from "../../../components/Api/Axios";
import BackendServices from "../../../components/Api/BackendService";
import verifyColumns from "./util/verifyColumns";
import VerifyRow from "./models/VerifyRow";
import SearchResponses from "../../../Models/APIGetData/SearchResponses";
import mapFilter from "./util/mapFilter";
import useGetCertificationStatuses from "../../../http/audit/useGetCertificationStatuses";
import VerifySummary from "../../../Models/Verify/VerifySummary";
import { VerifyUrls } from "../index";
import ContentPage from "../../../components/ContentPage";
import { isCBUser } from "../../../Authentication/Auth";
import useCountries from "../../../http/verify/useGetCountry";
import sortNameAlphanumerical from "../../../util/sorting/sortNameAlphanumerical";
import SimpleMenu from "../../../components/VerifyReportManagement/Index"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTableInputs } from "../../../store/details/actions/getTableFilterInputs";
import { useSelector } from "react-redux";
import TableInputFilter from "../../../Models/common/TableInputFilter";
import { RootState } from "../../../store/reducers";
const VerifySearchTable = () => {
  const history = useHistory()
  const dispatch=useDispatch()
  const inpuFilterFetchStore: TableInputFilter = useSelector(
      (state: RootState) => state.details.tableFilterInputs
    );
  interface LocationState{backClick?:boolean}
  let backNavigation=(history.location.state as LocationState)?.backClick

if(history.action=="POP")
{
  backNavigation=false
  
}
 
  const [reportfilter, setreportfilter] = useState<
    (DataTableQueryParams)
  >();

  
   const standardsFilter  ={
    "Agents and Brokers": "Agents and Brokers",
    "Consumer Products": "Consumer Products",
    "ETRS": "ETRS",
    "Food": "Food",
    "Gluten-Free": "Gluten-Free",
    "Packaging Materials": "Packaging Materials",
    "Plant Based": "Plant Based",
    "START!": "START!",
    "Storage and Distribution": "Storage and Distribution",
     };
const gradeFilter={
"AA+": "AA+",
"AA": "AA",
"A+": "A+",
"A": "A",
"B+": "B+",
"B":"B",
"C+":"C+",
"C":"C",
"D+":"D+",
"D": "D",
"Foundation +": "Foundation +",
"Foundation": "Foundation",
"No Grade": "No Grade"
}


const countryFilter  = mapFilter(useCountries().siteCountries.sort(sortNameAlphanumerical));

  const certificationFilter = mapFilter(
    useGetCertificationStatuses().certificationStatuses.sort(sortNameAlphanumerical)
  );


   interface DataTableQueryParams {
    readonly pageNumber: number;
    readonly orderBy?: string | number | symbol;
    readonly order: "asc" | "desc";
    readonly searchTerm: string;
    readonly [key: string]: string | string[] | number | symbol | undefined | boolean;
  
  }
 
  const fetch = (
        params: DataTableQueryParams,
        
        
  ): Promise<{ data: VerifyRow[]; totalPages: number }> => {
    setreportfilter(params);

    if(backNavigation==false||backNavigation == undefined) 
     {
     dispatch(setTableInputs(params))
    
     }
   
    return axiosApi
      .get<SearchResponses<VerifySummary>>(BackendServices.AUDIT_SERVICE.VERIFY, {
      
       params: (backNavigation == false||backNavigation == undefined) ? params : inpuFilterFetchStore
      }
      )
      .then(({ data }) => ({
        totalPages: data.totalPages,
        data: data.resultsList.map(
          (audit): VerifyRow => ({
            Id: audit.id.toString(),
            SiteName: audit.site.name,
            SiteId: audit.site.id,
            CertificationBody: audit.certificationBody.name,
            ProgramOwner: audit.programOwner,
            Standard: audit.standard.name,
            AuditOwner: audit.auditOwner,
            ExpiryDate:audit.expiryDate,
            IssueDate:audit.issueDate,
            CertificationStatus: audit.certificationStatus,
            AuditStatus: audit.auditStatus,
            Grade:audit.grade,
            Country:audit.country,
            City:audit.city,
            IsFavourited:audit.isFavourited,
            SharingPermissionName:audit.sharingPermissionName,
            HasCertificate:audit.hasCertificate,
              
          })
        
        ),
      }
     
        )
      
      )
      .finally(() =>
       {
           history.location.state={"backClick":false}
       }
    );
      ;
     
  };
  
 
  return (
    
    <ContentPage title="Verify">


<div style={{display: 'flex', justifyContent:'flex-end',paddingRight:40}}>
         <IconButton className="sizeSmall">
         <FontAwesomeIcon icon={faDownload} />
           <SimpleMenu searchterms={reportfilter}   ></SimpleMenu> 
           </IconButton>
 </div>
         
      <DataTable<VerifyRow>
        columns={verifyColumns}
           fetchRemoteData={fetch}
        filters={{
          Standard: () => standardsFilter,
          Country: () => countryFilter,
          CertificationStatus: () => certificationFilter,
          Grade: () => gradeFilter,
        }}
        rowActions={undefined}
        toolBarOptions={{
          newEntityLink: !isCBUser()? {
            label: "Add new audit",
            link: VerifyUrls.create(),
          }:undefined,
        }}
        favouriteFilter={{ enabled:true }}
        
       
      />
    </ContentPage>
  );
};

export default VerifySearchTable;
