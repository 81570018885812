import React, { useContext } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { idParam } from "../components/Paths/paths";
import ChangeLog from "../views/ChangeLog";
import Sharing from "../views/Sharing";
import GenericLinks from "./GenericLinks";
import PermissionArea from "../Authentication/model/PermissionArea";
import PermissionContext, {
  useUserPermission,
} from "./contexts/PermissionContext";
import { PermissionDeniedPage } from "../components/ErrorPage";
import Permission from "../Authentication/model/Permission";

const withParentContext = (
  Component: React.ComponentType<any>,
  parentContext: ParentContextDetails
) => (props: RouteComponentProps<any>) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  return (
    <ParentContext.Provider value={{ ...parentContext, id }}>
      <Component {...props} />
    </ParentContext.Provider>
  );
};

interface ParentContextDetails {
  readonly parentLink: string;
  readonly confirm?: boolean;
  readonly id?: string;
}

export const ParentContext = React.createContext<null | ParentContextDetails>(
  null
);

interface RequiredRoutingComponents {
  readonly searchComponent: React.ComponentType<any>;
  readonly detailsComponent: React.ComponentType<any>;
  readonly editComponent: React.ComponentType<any>;
  readonly createComponent: React.ComponentType<any>;
}

interface ApplicationRoute {
  readonly path: string;
  readonly component: React.ComponentType<any>;
  readonly requiredPermission?: Permission;
  readonly confirmOnLeave?: boolean;
}

const createRouting = ({
  root,
  components,
  detailsRoutes = [],
  rootRoutes = [],
}: GenericRouteConfig): ApplicationRoute[] => {
  const detailsPath = `${root}/details${idParam}`;

  const allDetailRoutes: ApplicationRoute[] = [
    ...(detailsRoutes ?? []),
    {
      path: "edit",
      component: components.editComponent,
      requiredPermission: Permission.REVISE,
    },
    {
      path: `change-log`,
      component: ChangeLog,
      requiredPermission: Permission.CHANGES,
    },
    { path: `share`, component: Sharing, confirmOnLeave: false },
  ];
  return [
    ...rootRoutes.map(({ component, path }) => ({
      path: `${root}/${path}`,
      component,
    })),
    ...allDetailRoutes.map(
      ({ path, component, requiredPermission, confirmOnLeave }) => ({
        path: `${detailsPath}/${path}`,
        component: withParentContext(component, {
          parentLink: detailsPath,
          confirm: confirmOnLeave ?? true,
        }),
        requiredPermission,
      })
    ),
    {
      path: detailsPath,
      component: withParentContext(components.detailsComponent, {
        parentLink: root,
      }),
      requiredPermission: Permission.VIEW,
    },
    {
      path: `${root}/create`,
      component: withParentContext(components.createComponent, {
        parentLink: root,
        confirm: true,
      }),
      requiredPermission: Permission.GENERATE,
    },
    {
      path: `${root}`,
      requiredPermission: Permission.VIEW,
      component: components.searchComponent,
    },
  ];
};

export type EntityType =
  | "audit"
  | "auditor"
  | "company"
  | "site"
  | "cb"
  | "user"
  | "recall"
  | "verify";

interface GenericRouteConfig {
  readonly root: string;
  readonly entityType: EntityType;
  readonly urls: GenericLinks;
  readonly components: RequiredRoutingComponents;
  readonly detailsRoutes?: ApplicationRoute[];
  readonly rootRoutes?: ApplicationRoute[];
  readonly permissionArea: PermissionArea;
}

export const EntityTypeContext = React.createContext<{
  readonly type: EntityType;
  readonly urls: GenericLinks;
} | null>(null);
const GenericRoutes = (config: GenericRouteConfig) => () =>{ 
 return (
  <EntityTypeContext.Provider
    value={{ type: config.entityType, urls: config.urls }}
  >
    <PermissionContext.Provider value={config.permissionArea}>
      <Switch>
        {createRouting(config).map((route) => (
          <ProtectedRoute
            permission={route.requiredPermission}
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
    </PermissionContext.Provider>
  </EntityTypeContext.Provider>
)};

const ProtectedRoute = ({
  path,
  component,
  permission,
}: {
  readonly path?: string | string[];
  readonly component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  readonly permission?: Permission;
}) => {
  const permissionArea = useContext(PermissionContext);
  let contextPermissions: Permission[] = [];
  const userPermission = useUserPermission();

  const getCBPermissions = () => {
    try {
      return [
        ...userPermission[PermissionArea.CB],
        ...userPermission[PermissionArea.SUB_OFFICE],
      ];
    } catch {
      return userPermission[PermissionArea.CB];
    }
  };

  if (permissionArea) {
    contextPermissions =
      permissionArea === PermissionArea.CB
        ? getCBPermissions()
        : userPermission[permissionArea];
  }
  if (!contextPermissions) {
    contextPermissions = [];
  }

  const isPermitted = !permission || contextPermissions.includes(permission);
  return isPermitted ? (
    <Route path={path} component={component} />
  ) : (
    <PermissionDeniedPage permission={permission} />
  );
};

export default GenericRoutes;
