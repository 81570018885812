import React, { useState } from "react";

import { Column, EditCellColumnDef } from "material-table";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/reducers";



  interface CityFilterProps<T extends object> {
    readonly columnDef: Column<T> & EditCellColumnDef;
    readonly onFilterChanged: (rowId: string, value: any) => void;
  }
  const VerifyCityFilter = ({
    columnDef,
    onFilterChanged,
  }: CityFilterProps<any>) => {
 

 const useinputStoreselector: any = useSelector(
    (state: RootState) => state.details.tableFilterInputs
  );


    const [cityChangePerformed, setcityChangesPerformed] = useState(false);
  
   const [inputval, handleFilterChange] = useState(useinputStoreselector?.City==""?"":useinputStoreselector?.City);
   
   
   
    const handleKeyDown=(evnt: React.KeyboardEvent<HTMLInputElement>,inputval: string)=>{
      if(evnt.key =="Enter")
      {
           setcityChangesPerformed(true)
      }
      }
  
      if (cityChangePerformed) {
        onFilterChanged(
          `${columnDef.tableData.id}`,
          `${inputval}`
        );
        setcityChangesPerformed(false)
      }
  
      
      return (
          <input
          type="text"
           value={inputval} className="verifycityfilter"
          onKeyDown={(event) => handleKeyDown(event, inputval)}
         onChange={(event) => handleFilterChange(event.target.value)}
        />
      );
  }
 
  export default VerifyCityFilter;