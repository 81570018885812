import React, { useContext, useState } from "react";
import Column from "../../../../components/DataTable/models/Column";
import { LinkText } from "../../../../components/Common/hyperlinks";
import VerifyRow from "../models/VerifyRow";
import { VerifyUrls } from "../../index";
import { Checkbox, Tooltip, withStyles } from "@material-ui/core";
import FavCheckbox from "../../FavouritingAudits";
import  auditSharingPermission from "../../../../../src/Enums/AuditSharingPermission";
import { IconButton } from "@material-ui/core";
import downloadCertificate from "../../http/downloadCertificate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { dispatch } from "../../../../components/App/App";
import setError from "../../../../store/error/actions/setError";
import VerifyPDFToolTip from "../../../../Enums/VerifyPDFToolTip";
import { Link } from "react-router-dom";

const PDFTooltip = withStyles({
  tooltip: {
    color: "white",
    backgroundColor: "grey",
    width:"200px"
  }
})(Tooltip);

// const myTable=({params}:{params?:DataTableQueryParams|undefined}={params:undefined})=>{
const verifyColumns: Column<VerifyRow>[] = [
  {

    title: "Audit Id",
    field: "Id",
    render: (rowData: VerifyRow) => (
     
      rowData.SharingPermissionName ===  auditSharingPermission.CertificateOnly ||
      rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF ? (
 
        <Link to={{
          pathname: VerifyUrls.verifyView(rowData.Id),
          state:{ backClick:false }
         }}>{rowData.Id}</Link>

      
        ):"")
    
  },

  { title: "Site",
     field: "SiteName"
  },
  {
    title: "Site Code",
    field: "SiteId"
    
  },
  {
    title: "City",
    field: "City",
    filtering: true,

  },

  {
    title: "Country",
    field: "Country",
    filtering: true,
 
  },

  {
    title: "Certification Body",
    field: "CertificationBody"
  
  },

  {
    title: "Standard",
    field: "Standard"
   
  },
  {
    title: "Grade",
    field: "Grade"
   
  },
  {
    title: "Issue Date",
    field: "IssueDate",
    type: "date"
   
  },
  {
    title: "Expiry Date",
    field: "ExpiryDate",
    type: "date"
  
  },
  {
    title: "Certification Status",
    field: "CertificationStatus",
    render: (rowData: VerifyRow) => 
      (
      rowData.CertificationStatus ===  "Certificated" ? (  
        <label>
        {rowData.CertificationStatus}        
      </label> 
 
    ):
    <label className="colorRed">
    {rowData.CertificationStatus}        
  </label> )
  },

  {
    title: "Favourites",
    field: "IsFavourited",
    render: (rowData: VerifyRow) => (
      (rowData.SharingPermissionName ===  auditSharingPermission.CertificateOnly ||
      rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF ? (
      <FavCheckbox id={rowData.Id} fav={rowData.IsFavourited} /> 

    ):"")
  
  
  )
    
  },
  
  {
    title: "Certificate PDF",
    field: "IsDownloaded",
    render: (rowData: VerifyRow) => 
      (rowData.SharingPermissionName ===  auditSharingPermission.CertificatePDF &&
      ((rowData.CertificationStatus ===  "Certificated" )|| (rowData.CertificationStatus !="Certificated" )) ? (
  
 <PDFTooltip title={rowData.HasCertificate === true && (rowData.CertificationStatus ===  "Certificated" ) ? "":((rowData.HasCertificate === true||rowData.HasCertificate === false) && (rowData.CertificationStatus != "Certificated" ))?VerifyPDFToolTip.CertificatePDFNotCertificated :VerifyPDFToolTip.CertificatePDFCertificatednoFile}
        
        > 
        
     <span>     
  <IconButton  disabled={rowData.HasCertificate === true && rowData.CertificationStatus ===  "Certificated"? false : true}
  onClick={()=>
  {
    if(rowData.HasCertificate === true)
    {
    downloadCertificate(rowData.Id);
    }
  
  }
  }
 
>
  <FontAwesomeIcon icon={faFilePdf} />
</IconButton>
     </span>
</PDFTooltip>



    ):(

      <PDFTooltip title={rowData.SharingPermissionName ===  auditSharingPermission.BasicOnly ||rowData.SharingPermissionName ===  auditSharingPermission.CertificateOnly ? VerifyPDFToolTip.BasicOnlyORCertificateOnly:"" }
      
      > 
   <span>     
<IconButton  disabled={true}

>
<FontAwesomeIcon icon={faFilePdf} />
</IconButton>
</span>
</PDFTooltip>
  )
)
  },

];
//}
export default verifyColumns;
