import React, { Component, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ContentPage from "../../components/ContentPage";
import TabsBar from "../../components/Common/Tab";
import Share from "./components/Share";
import Unshare from "./components/Unshare";
import getShareEntityDetails, {
  SharableDetails,
} from "./http/getShareEntityDetails";
import SharingType from "./models/SharingType";
import { EntityTypeContext } from "../../routing/GenericRoutes";
import Snackbar from "../../components/Snackbar";
import { usePermissionContext } from "../../routing/contexts/PermissionContext";
import { isCompanyBiUser } from "../../Authentication/Auth";
import { title } from "process";
import ShareOptions from "./components/AuditSharingOptions";
import getAuditDetails from "../../http/audit/getAuditDetails";
import AuditDetailsView from "../../Models/Audit/AuditDetailsView";
import getPermissions from "../../store/permissions/actions/getPermissions";
import axiosApi from "../../components/Api/Axios";
import BackendServices from "../../components/Api/BackendService";
import getPermissionDetails from "../../http/audit/getPermissionDetails";
import { useLocation } from 'react-router-dom';
import { getCompanyId,isBRCGSAdmin} from "../../Authentication/Auth";
/*
Results are not paginated as of today for the unshared table, but the table is.
This causes the checked state to "reset" on page change.
Storing changed values as a workaround until server returns paginated results
to avoid rewriting the table just for this case.
 */

export const updatedCells: {
  [key: number]: boolean;
} = {};


const Sharing = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ readonly id: string }>) => {
  const type = useContext(EntityTypeContext)?.type as SharingType;
  const [getPermission, setPermission] = useState(false);
  useEffect(() => {
    getPermissionDetails().then(res => setPermission(res.data))
  },[getPermission]);
  //const checkPermission= axiosApi.get(`${BackendServices.USER_SERVICE.USER}/checkSahringOptions`).then(res => setPermission(res.data));
  const [itemData, setData] = useState<SharableDetails | null>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState<{
    readonly shared: boolean;
    readonly name: string;
  } | null>(null);
  const [itemCertiData, setCertiData] = useState<string>("");
  useEffect(() => {
    if (id) {
      getAuditDetails(id)
        .then((data) => setCertiData(data.data.certificationStatus.name))
        .catch(() => {

        });
    }
  }, [id]);

  const location = useLocation();
  const StatusList = ["Pending", "Start! Intermediate", "Start! Basic", "Certificated", "Expired", "Expired, Renewed", "Interim"];
  const showStatus = StatusList.includes(itemCertiData) && getPermission;
  const isVerifySiteSettings =  location.pathname.indexOf("verify-settings") > 0;
  const permissions = usePermissionContext();
  const companyId = Number(getCompanyId());
  const isBRCGSAdminUser = Number(isBRCGSAdmin());
  const showVerifySettings = ((itemData?.companyId == companyId || isBRCGSAdminUser) && itemData?.isAbleToShare );
  useEffect(() => {
    if (id && type) {
      getShareEntityDetails(id, type, permissions)
        .then((data) => setData(data))
        .catch(() => {
          setData(null);
        });
    }
  }, [id, type, permissions]);

  useEffect(() => {
    return () => {
      Object.keys(updatedCells).forEach((key) => delete updatedCells[+key]);
    };
  }, []);

  if (!id) {
    return <p>Missing ID</p>;
  }
  if (!Object.values(SharingType).some((val) => type === val)) {
    return <p>Missing type</p>;
  }

  if ((itemData && !itemData.isAbleToRemoveShare && !itemData.isAbleToShare) || (isVerifySiteSettings && itemData && !showVerifySettings)) {
    return (
      <ContentPage title={ isVerifySiteSettings ? `${itemData?.name ?? ""}` :`Share ${itemData?.name ?? ""}`}>
        <p>{isVerifySiteSettings && !showVerifySettings? 'You are not allowed to change verify settings' : 'You are not allowed to share / unshare this resource'}</p>
      </ContentPage>
    );
  }

  const tabs: { title: string; component: JSX.Element }[] = [
    ...(!isVerifySiteSettings && itemData?.isAbleToShare
      ? [
        {
          title: "Share",
          component: (
            <Share
              id={id}
              type={type}
              setShowConfirmation={setShowConfirmation}
            />
          ),
        },
      ]
      : []),
    ...(!isVerifySiteSettings && itemData?.isAbleToRemoveShare
      ? [
        {
          title: "Shared with",
          component: (
            <Unshare
              id={id}
              type={type}
              setShowConfirmation={setShowConfirmation}
            />
          ),
        },
      ]
      : []),
    ...(((isVerifySiteSettings && showVerifySettings )|| (showStatus && itemData?.isAbleToShare))? [
      {
        title: "Verify Access",
        component: (
          <ShareOptions
            id={id}
            type={type}
            setShowConfirmation={setShowConfirmation}
          />
        ),
      },
    ]
      : []),
  ];

  return (
    <ContentPage title={isVerifySiteSettings ? ` ${itemData?.name ?? ""}` : `Share ${itemData?.name ?? ""}`}>
      <TabsBar
        value={selectedTabIndex}
        onChange={setSelectedTabIndex}
        labels={tabs.map((tab) => tab.title)}
        centered={false}
      />
      {tabs[selectedTabIndex]?.component}
      <Snackbar
        message={`${showConfirmation?.shared ? "Shared" : "No longer sharing"
          } with ${showConfirmation?.name}`}
        close={() => setShowConfirmation(null)}
        open={!!showConfirmation}
      />
    </ContentPage>
  );
};

export default Sharing;
