import {
  ACTION_GET_STANDARDS,
  ActionGetStandards,
} from "./actions/getStandards";
import Category from "../../Models/common/Category";
import {
  ACTION_GET_CATEGORIES,
  ActionGetCategories,
} from "./actions/getCategories";
import Country from "../../Models/common/Country";
import {
  ACTION_GET_COUNTRIES,
  ActionGetCountries,
} from "./actions/getCountries";
import {
  ACTION_GET_QUALIFICATION_STATUS,
  ActionGetQualificationStatus,
} from "./actions/getQualificationStatus";
import {
  ACTION_CLEAR_TABLE_FILTER_INPUTS,
  ACTION_GET_TABLE_FILTER_INPUTS,
  ACTION_RESET_TABLE_FILTER_SEARCHTERM,
  ActionType
} from "./actions/getTableFilterInputs";
import QualificationStatus from "../../Models/common/QualificationStatus";
import Standard from "../../Models/common/Standard";
import sortNameAlphanumerical from "../../util/sorting/sortNameAlphanumerical";
import TableInputFilter from "../../Models/common/TableInputFilter";

interface DetailsState {
  readonly standards: Standard[];
  readonly categories: Category[];
  readonly countries: Country[];
  readonly qualificationStatus: QualificationStatus[];
  readonly tableFilterInputs:TableInputFilter;
}

const initialState: DetailsState = {
  categories: [],
  standards: [],
  countries: [],
  qualificationStatus: [],
  tableFilterInputs:{
    searchTerm: "",
    showFavourited:false
  }
};

type DetailActions =
  | ActionGetStandards
  | ActionGetCategories
  | ActionGetCountries
  | ActionGetQualificationStatus
  |ActionType;
const detailsReducer = (
  state = initialState,
  action: DetailActions
): DetailsState => {
  switch (action.type) {
    case ACTION_GET_STANDARDS:
      return {
        ...state,
        standards: action.payload.sort(sortNameAlphanumerical),
      };
    case ACTION_GET_CATEGORIES:
      return { ...state, categories: action.payload };
    case ACTION_GET_COUNTRIES:
      return { ...state, countries: action.payload };
    case ACTION_GET_QUALIFICATION_STATUS:
      return { ...state, qualificationStatus: action.payload };
    case ACTION_GET_TABLE_FILTER_INPUTS:
        return { ...state, tableFilterInputs: action.payload };
    case ACTION_CLEAR_TABLE_FILTER_INPUTS:
          return { ...initialState};
          case ACTION_RESET_TABLE_FILTER_SEARCHTERM:
            return {
              ...state,
              tableFilterInputs: {
                ...state.tableFilterInputs,
                searchTerm: action.payload??'', // Updating only `searchTerm`
              },
            }
    default:
      return state;
  }
};

export default detailsReducer;
