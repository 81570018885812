import { Query } from "material-table";
import { DataTableQueryParams } from "../index";
import { DateRangeValue } from "../../DateRangePicker";

interface AdditionalFilters {
  readonly dateRange: DateRangeValue;
}

export default <T extends {}>(
  query: Query<T>,
  additionalFilters: AdditionalFilters,
  externalFilter: { [key: string]: any } = {},
  searchTermBackRestore:string
): DataTableQueryParams => {
  const selectedFilters = query.filters.reduce<{
    [key: string]: string | string[];
  }>((acc, curr) => {
    const {
      value,
      column: { field, type },
    } = curr;
    const fieldName = field as string;
    if (type === "date") {
      const [fromDate, toDate] = value ? value.split("&") : [];
      if (fromDate) acc[`${fieldName}Start`] = fromDate;
      if (toDate) acc[`${fieldName}End`] = toDate;
    } else {
      const selected = Array.isArray(value) ? value : [value];
      if (selected.length > 0) {
        acc[fieldName] = selected;
      }
    }
    return acc;
  }, {});

  const { dateRange, ...remainingAdditionalFilters } = additionalFilters;

  return {
    ...selectedFilters,
    order: query.orderDirection,
    orderBy: query.orderBy?.field,
    pageNumber: query.page + 1,
    searchTerm: searchTermBackRestore,
    ...(dateRange[0] !== null ? { startDate: dateRange[0].toISOString() } : {}),
    ...(dateRange[1] !== null ? { endDate: dateRange[1].toISOString() } : {}),
    ...remainingAdditionalFilters,
    ...externalFilter,
  };
};
