import React, { useEffect, useState } from "react";
import UserDetails from "../../../models/UserDetails";
import ListEntry from "../../../../../Models/CommonProps/ListEntry";
import styles from "./userInfoBox.module.scss";
import InfoBox from "../../../../../components/Infobox";
import { MinifiedOrganisationType } from "../../../UserNew/constants";
import axiosApi from "../../../../../components/Api/Axios";
import BackendServices from "../../../../../components/Api/BackendService";
import Office from "../../../../../Models/Office/Office";
import OrganisationLinkText from "./OrganisationLinkText";

interface UserInfoBoxProps {
  readonly user: UserDetails;
}

const UserInfoBox = ({ user }: UserInfoBoxProps) => {
  const [mappedCBDetails, setMappedCBDetails] = useState<{
    [key: number]: number;
  }>({});
  useEffect(() => {
    [user.primaryOrganisation, ...(user.secondaryOrganisations ?? [])].forEach(
      (org) => {
        if (
          org != null &&
          org.type === MinifiedOrganisationType.CertificationBody
        ) {
          axiosApi
            .get<Office[]>(
              `${`${BackendServices.CERTIFICATION_SERVICE.CERTIFICATION_BODY}/${org.externalId}`}/Office`
            )
            .then(({ data }) => data)
            .catch(() => [])
            .then((offices) => {
              const mainOffice = offices.find((o) => o.isMainOffice);
              if (mainOffice) {
                setMappedCBDetails((prev) => ({
                  ...prev,
                  [org.externalId]: mainOffice.id,
                }));
              }
            });
        }
      }
    );
  }, [user]);

  const items: Array<ListEntry> = [
   
    { label: "Email", value: user.email ?? "" },
    {
      label: "Name",
      value: `${user.firstName} ${user.lastName}` ?? "",
    },
    { label: "Job Title", value: user.jobTitle ?? "" },
    { label: "Work phone", value: user.phone ?? "" },
    {
      label: "Primary organisation",
      value: () => (
        <OrganisationLinkText
          mappedCBDetails={mappedCBDetails}
          organisation={user.primaryOrganisation}
        />
      ),
    },
    {
      label: "Secondary organisation(s)",
      value: () => (
        <div className={styles.secOrgField}>
          {user.secondaryOrganisations?.map((org) => (
            <OrganisationLinkText
              key={org.externalId}
              organisation={org}
              mappedCBDetails={mappedCBDetails}
            />
          ))}
        </div>
      ),
    },
    { label: "Role", value: user.roleName ?? "" },
    { label: "Status", value: user.status ?? "" },
  ].filter((item) => !!item.value);
  return <InfoBox header="Details" items={items} />;
};

export default UserInfoBox;
