import { IconButton, InputAdornment } from "@material-ui/core";
import React, { useState } from "react";
import { Search } from "@material-ui/icons";
import clsx from "clsx";
import { Text } from "../Common/Input/Text";
import styles from "./SearchBox.module.scss";
import { dispatch } from "../App/App";
import { resetSearchTerm } from "../../store/details/actions/getTableFilterInputs";

const ENTER_KEY = 13;

interface SearchBoxProps {
  searchString?: string;
  onUpdateSearch?: (s: string) => void;
  handleClick: (val: string) => void;
  placeHolderString: string;
  className?: string;
}

export default function SearchBox({
  handleClick,
  searchString,
  onUpdateSearch,
  placeHolderString,
  className,
}: SearchBoxProps) {
  const [uncontrolledInput, setUncontrolledInput] = useState(
    searchString ?? ""
  );

  const finalString = onUpdateSearch ? searchString ?? "" : uncontrolledInput;
  let UrlCheck: string;
    if (typeof window !== 'undefined') {
      const str = window.location.pathname
      const pieces = str.split('/')
       UrlCheck = pieces[pieces.length - 1];
    }
  return (
    <div className={clsx(styles.searchBox, className)}>
      <Text 
        autoFocus
        className={clsx(styles.searchTextField, "input text-field")}
        placeholder={placeHolderString}
        value={finalString}
        onKeyDown={(event) => {
          if (event.keyCode === ENTER_KEY) {
            
            handleClick(finalString);
          }
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>{
         const value=event.target.value;
          if(onUpdateSearch)
            { onUpdateSearch(event.target.value) 
if(UrlCheck=="verify")
              {dispatch(resetSearchTerm(event.target.value));}
            }else{
            setUncontrolledInput(event.target.value);
            }
        }
      }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => handleClick(finalString)}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
